import { createClient } from 'contentful';

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

export async function getAllPosts() {
  try {
    const entries = await client.getEntries({
      content_type: 'blogPost',
      order: '-sys.createdAt',
    });

    if (!entries.items) {
      throw new Error('Error fetching entries.');
    }

    const posts = entries.items.map((item) => ({
      id: item.sys.id,
      title: item.fields.title || 'Untitled Post',
      preview: item.fields.preview || item.fields.blogpost.substring(0, 150) + '...',
      content: item.fields.blogpost || '',
      date: item.sys.createdAt,
    }));

    return posts;
  } catch (error) {
    console.error('Contentful error:', error);
    throw new Error(`Failed to fetch posts: ${error.message}`);
  }
}

export async function getPostById(id) {
  try {
    const entry = await client.getEntry(id);

    return {
      id: entry.sys.id,
      title: entry.fields.title || 'Untitled Post',
      content: entry.fields.blogpost || '',
      date: entry.sys.createdAt,
    };
  } catch (error) {
    console.error('Contentful error:', error);
    throw new Error(`Failed to fetch post: ${error.message}`);
  }
}

export async function getAboutContent() {
  try {
    const entries = await client.getEntries({
      content_type: 'aboutPage',
      limit: 1,
    });

    if (!entries.items || entries.items.length === 0) {
      throw new Error('No about page content found');
    }

    const aboutData = entries.items[0];
    return {
      title: aboutData.fields.title || 'About Me',
      bio: aboutData.fields.bio || '',
      imageUrl: aboutData.fields.profileImage?.fields?.file?.url || null,
    };
  } catch (error) {
    console.error('Contentful error:', error);
    throw new Error(`Failed to fetch about page content: ${error.message}`);
  }
} 