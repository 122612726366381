import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react"
import Navbar from './components/NavBar';
import MobileNav from './components/MobileNav';
import useDeviceDetect from './hooks/useDeviceDetect';
import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import About from './pages/About';
import Contact from './pages/Contact';
import FunStuff from './pages/FunStuff';
import { ThemeProvider } from './context/ThemeContext';
import './App.css';

function App() {
  const { isMobile } = useDeviceDetect();

  return (
    <ThemeProvider>
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <main className="App-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:slug" element={<BlogPost />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/fun-stuff" element={<FunStuff />} />
            </Routes>
          </main>
          {isMobile && <MobileNav />}
        </div>
      </BrowserRouter>
      <Analytics />
      <SpeedInsights />
    </ThemeProvider>
  );
}

export default App;
