import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getPostById } from '../utils/contentful';
import BlogPostFull from '../components/BlogPostFull';

const BlogPostPage = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug: id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loadPost = async () => {
      try {
        setLoading(true);
        const postData = await getPostById(id);
        setPost(postData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    loadPost();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!post) return <div>Post not found</div>;

  return (
    <div className="blog-post-page">
      <button onClick={() => navigate('/blog')} className="back-button">
        ← Back to Blog
      </button>
      <BlogPostFull post={post} />
    </div>
  );
};

export default BlogPostPage; 