import React from 'react';
import ReactMarkdown from 'react-markdown';

const BlogPostFull = ({ post }) => {
  const { title, content, date } = post;

  return (
    <article className="blog-post-full">
      <h1>{title}</h1>
      <time>{new Date(date).toLocaleDateString()}</time>
      <div className="blog-content">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </article>
  );
};

export default BlogPostFull; 