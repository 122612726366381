import React, { useEffect, useState } from 'react';
import { getAllPosts } from '../utils/contentful';
import ReactMarkdown from 'react-markdown';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadPosts = async () => {
      try {
        setLoading(true);
        const allPosts = await getAllPosts();
        setPosts(allPosts);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    loadPosts();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="blog-list-container">
      <h1>All Blog Posts</h1>
      <div className="blog-list">
        {posts.map(post => (
          <article key={post.id} className="blog-list-item">
            <h2>{post.title}</h2>
            <time>{new Date(post.date).toLocaleDateString()}</time>
            <div className="blog-content">
              <ReactMarkdown>{post.content}</ReactMarkdown>
            </div>
          </article>
        ))}
      </div>
    </div>
  );
};

export default Blog; 