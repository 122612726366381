import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { SiBluesky } from 'react-icons/si';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    error: null
  });
  const form = useRef();

  // Initialize EmailJS
  useEffect(() => {
    emailjs.init("T9OSfolZ0vzgafus1");
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ submitted: false, submitting: true, error: null });

    try {
      const result = await emailjs.sendForm(
        "service_7zgiykc",
        "template_msmd7jt",
        form.current,
        "T9OSfolZ0vzgafus1"
      );

      console.log('EmailJS response:', result);

      setStatus({
        submitted: true,
        submitting: false,
        error: null
      });
      
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
      
      setTimeout(() => {
        setStatus(prev => ({ ...prev, submitted: false }));
      }, 5000);

    } catch (error) {
      console.error('Detailed email error:', error);
      setStatus({
        submitted: false,
        submitting: false,
        error: `Failed to send message: ${error.message}`
      });
    }
  };

  return (
    <div className="contact-container">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="contact-content"
      >
        <div className="contact-info">
          <h1>Let's Connect!</h1>
          <p>I'm always interested in hearing about new projects and opportunities.</p>
          
          <div className="contact-methods">
            <div className="contact-method">
              <h3>📧 Email</h3>
              <a href="mailto:me@jeremygloger.com" className="email-link">me@jeremygloger.com</a>
            </div>
            
            <div className="contact-method">
              <h3>🌐 Social</h3>
              <div className="social-links">
                <a href="https://github.com/jeremygloger" target="_blank" rel="noopener noreferrer">
                  <FaGithub className="social-icon" /> GitHub
                </a>
                <a href="https://www.linkedin.com/in/jeremy-g-b5037012a/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin className="social-icon" /> LinkedIn
                </a>
                <a href="https://bsky.app/profile/jeremygloger.com" target="_blank" rel="noopener noreferrer">
                  <SiBluesky className="social-icon" /> Bluesky
                </a>
              </div>
            </div>
          </div>
        </div>

        <motion.form 
          ref={form}
          className="contact-form"
          onSubmit={handleSubmit}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              disabled={status.submitting}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              disabled={status.submitting}
            />
          </div>

          <div className="form-group">
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
              disabled={status.submitting}
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              rows="5"
              disabled={status.submitting}
            />
          </div>

          {status.error && (
            <div className="form-error">
              {status.error}
            </div>
          )}

          {status.submitted && (
            <div className="form-success">
              Message sent successfully!
            </div>
          )}

          <button 
            type="submit" 
            className="submit-button"
            disabled={status.submitting}
          >
            {status.submitting ? 'Sending...' : 'Send Message'}
          </button>
        </motion.form>
      </motion.div>
    </div>
  );
};

export default Contact;
