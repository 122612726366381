// src/pages/About.js
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import { getAboutContent } from '../utils/contentful';

const About = () => {
  const [aboutData, setAboutData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadAboutContent = async () => {
      try {
        setLoading(true);
        const content = await getAboutContent();
        setAboutData(content);
      } catch (err) {
        console.error('Error loading about content:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadAboutContent();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading content: {error}</div>;
  if (!aboutData) return <div>No content found</div>;

  return (
    <motion.div 
      className="about-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="about-content">
        <motion.h1 
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{ delay: 0.2 }}
        >
          {aboutData.title}
        </motion.h1>
        
        <div className="about-grid">
          <motion.div 
            className="about-image"
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            {aboutData.imageUrl && (
              <img 
                src={aboutData.imageUrl} 
                alt="Profile" 
                className="profile-image"
              />
            )}
          </motion.div>
          
          <motion.div 
            className="about-text"
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            <ReactMarkdown>{aboutData.bio}</ReactMarkdown>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default About;