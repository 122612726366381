import React, { useEffect, useState } from 'react';
import BlogPost from '../components/BlogPost';
import { getAllPosts } from '../utils/contentful';

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadPosts = async () => {
      try {
        setLoading(true);
        const allPosts = await getAllPosts();
        setPosts(allPosts.slice(0, 3)); // Only show the 3 most recent posts
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    loadPosts();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="home-container">
      <h1>Welcome to My Blog</h1>
      <p className="home-intro">Check out my latest posts below</p>
      <div className="blog-grid">
        {posts.map(post => (
          <BlogPost key={post.id} post={post} />
        ))}
      </div>
    </div>
  );
};

export default Home;
