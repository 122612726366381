import React from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

const BlogPost = ({ post }) => {
  const { id, title, preview, date } = post;
  
  return (
    <article className="blog-post">
      <div className="blog-post-content">
        <h2>{title}</h2>
        <time>{new Date(date).toLocaleDateString()}</time>
        <div className="preview-content">
          <ReactMarkdown>{preview}</ReactMarkdown>
        </div>
        <Link to={`/blog/${id}`} className="read-more">
          Read More →
        </Link>
      </div>
    </article>
  );
};

export default BlogPost; 